<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      :lw="3"
      @search="search"
    />
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        <b-link
          :to="{ name: 'member-adv-view', params: { idx: data.item.idx } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.idx }}
        </b-link>
      </template>

      <template #cell(name)="data">
        <b-media vertical-align="center">
          <b-link
            :to="{ name: 'member-adv-view', params: { idx: data.item.idx } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
            <small class="text-muted">{{ data.item.email }}</small>
          </b-link></b-media>
      </template>

      <template #cell(state)="data">
        <div
          style="max-width: 90px;"
          :class="data.item.state"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(business_confirm_state)="data">
        <div
          style="max-width: 90px;"
          :class="data.item.business_confirm_state"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(subscription__canceled_at)="data">
        <TagbyDateColumn
          v-if="data.item.subscription != null"
          :value="data.item.subscription.canceled_at"
        />
      </template>

      <template #cell(created_at)="data">
        <div style="max-width: 90px;">
          {{ data.value }}
        </div>
      </template>
    </tagby-list>
  </div>
</template>

<script>
import {
  BMedia,
  BLink,
} from 'bootstrap-vue'
import AdvertiserListViewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDateColumn from '@/components/TagbyDateColumn.vue'

export default {
  components: {
    BMedia,
    BLink,
    TagbyFilter,
    TagbyList,
    TagbyDateColumn,
  },
  setup() {
    return {
      ...AdvertiserListViewModel(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.WITHDRAW {
  color: red;
}

.WAIT {
  color: yellow;
}
</style>
