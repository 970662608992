import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import AdvertiserStoreModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-advertiser'

  const toast = useToast()
  const routerQuery = router.history.current.query
  const initialSearchParams = Object
    .keys(routerQuery)
    .reduce((previoutValue, currentValue) => {
      if (['page', 'size', 'total'].includes(currentValue)) {
        return previoutValue
      }
      if (currentValue === null || currentValue === '') {
        return previoutValue
      }
      if (typeof routerQuery[currentValue] === 'string') {
        return {
          ...previoutValue,
          [currentValue]: [routerQuery[currentValue]],
        }
      }
      return {
        ...previoutValue,
        [currentValue]: routerQuery[currentValue],
      }
    }, {})
  const searchParams = ref({
    page: parseInt(routerQuery.page, 10) || 1,
    size: parseInt(routerQuery.size, 10) || 20,
    total: parseInt(routerQuery.total, 10) || 0,
    ...initialSearchParams,
  })

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => { })
  }

  const categoryOptions = [
    {
      label: '이름',
      value: 'NAME',
      paramName: 'name',
    },
    {
      label: '휴대번호',
      value: 'PHONE',
      paramName: 'phone',
    },
    {
      label: '이메일',
      value: 'EMAIL',
      paramName: 'email',
    },
    {
      label: 'idx',
      value: 'idx',
      paramName: 'idx',
    },
    {
      label: '회사명',
      value: 'COMPANY_NAME',
      paramName: 'company_name',
    },
    {
      label: '유저타입',
      value: 'MEMBER_CODE',
      paramName: 'member_code',
      format: 'checkbox',
      options: [
        { text: '태그바이직원', value: 'TAGGER' },
        { text: '일반직원', value: 'USER' },
      ],
    },
    {
      label: '벤더여부',
      paramName: 'is_vendor',
      format: 'checkbox',
      options: [
        { text: '벤더', value: true },
        { text: '일반사용자', value: false },
      ],
    },
    {
      label: '상태',
      value: 'STATE',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: '활성', value: 'ACTIVE' },
        { text: '대기', value: 'WAIT' },
        { text: '삭제', value: 'WITHDRAW' },
        { text: '블랙', value: 'BAN' },
      ],
    },
    {
      label: '구독 중인 상품',
      value: 'SUBSCRIPTION',
      paramName: 'subscription',
      format: 'checkbox',
      options: [
        { text: '인플루언서 매니져 FREE', value: 2 },
        { text: '인플루언서 매니져 BASIC', value: 3 },
        { text: '인플루언서 매니져 PREMIUM', value: 4 },
      ],
    },
    {
      label: '구독 지불 연체 여부',
      value: 'is_subscription_overdue',
      paramName: 'is_subscription_overdue',
      format: 'checkbox',
      options: [
        { text: '정상지불', value: false },
        { text: '연체중', value: true },
      ],
    },
    {
      label: '구독종료일시',
      value: 'SUBSCRIPTION_END_AT',
      paramName: 'subscription_end_at',
      format: 'date',
    },
    {
      label: '구독취소일시',
      value: 'SUBSCRIPTION_CANCEL_AT',
      paramName: 'subscription_cancel_at',
      format: 'date',
    },
    {
      label: '단건 결제 이용 여부',
      paramName: 'has_paug',
      format: 'checkbox',
      options: [
        { text: 'True', value: true },
        { text: 'False', value: false },
      ],
    },
    {
      label: '가입일시',
      value: 'CREATED_AT',
      paramName: 'created_at',
      format: 'date',
    },
    {
      label: '추천코드 여부',
      paramName: 'has_invite_code',
      format: 'checkbox',
      options: [
        { text: 'true', value: true },
        { text: 'false', value: false },
      ],
    },
    {
      label: '추천코드',
      paramName: 'invite_code',
    },
    {
      label: '브랜드SNS 보유 여부',
      paramName: 'has_brand_sns',
      format: 'checkbox',
      options: [
        { text: 'true', value: true },
        { text: 'false', value: false },
      ],
    },
    {
      label: '가입채널',
      paramName: 'channel',
      format: 'checkbox',
      options: [
        { text: 'TAGBY', value: 'TAGBY' },
        { text: 'CAFE24', value: 'CAFE24' },
      ],
    },
  ]

  const tableColumns = [
    { key: 'idx' },
    { key: 'company_name' },
    { key: 'name' },
    { key: 'phone' },
    { key: 'state' },
    { key: 'subscription.end_at', label: '현재구독종료일시', formatter: val => (moment(val).isValid() ? moment(val).format('YYYY-MM-DD') : null) },
    { key: 'subscription.continue_count', label: '현재구독유지기간' },
    { key: 'subscription__canceled_at', label: '마지막구독취소일시' },
    { key: 'paug_count', label: '건별결제 이용횟수' },
    { key: 'created_at', label: '가입일시', formatter: val => moment(val).format('YYYY-MM-DD HH:mm:ss') },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const currentPage = ref(parseInt(searchParams.value.page, 10) ?? 1)
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchAdvertiserList`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const search = params => {
    Object.assign(searchParams.value, { ...params, page: 1 })
    fetchItems()
  }

  const changePage = params => {
    Object.assign(searchParams.value, params)
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, AdvertiserStoreModule)
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,
  }
}
