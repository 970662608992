import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // 광고주 리스트 받기
    fetchAdvertiserList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 디테일 받기
    fetchAdv(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/detail/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 타임라인 받기
    fetchAdvTimeline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/timelines/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 업데이트
    updateAdv(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update/`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 업데이트
    cautionAdv(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/caution/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 업데이트
    blackAdv(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/ban/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getAdvCautions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/cautions/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 포인트 관리
    pointAdv(ctx, pointForm) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/point/`, pointForm)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    advUpdateCautionState(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update-caution-state/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    advUpdateCautionExpiredAt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update-caution-expired_at/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
